// @flow

import React from 'react'
import type { Node } from 'react'

import type { FieldProps } from 'formik'

import { TextInput } from '../text-input'
import { FieldError } from '../field-error'

type InputGroupProps<Values> = {
  name: string,
  placeholder: string,
  field: FieldProps<Values>['field'],
  form: FieldProps<Values>['form'],
  label: Node,
  required?: boolean,
}

export function InputGroup<Values: any>({
  field,
  form,
  placeholder,
  label,
  required,
}: InputGroupProps<Values>): Node {
  const { name, value, onChange, onBlur } = field
  const { errors, touched } = form

  return (
    <div>
      <div className="mb-2">{label}</div>
      <FieldError formik={{ field, form }}>
        <TextInput
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          error={touched[field.name] && errors[field.name]}
          placeholder={placeholder}
          required={required}
        />
      </FieldError>
    </div>
  )
}
InputGroup.defaultProps = {
  required: false,
}

export default InputGroup
