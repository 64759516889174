// @flow

import React from 'react'
import type { Node, ComponentType } from 'react'
import styled from 'styled-components'

import theme from '../../../tailwind'

type Props = {
  children?: Node,
  backgroundImage: string,
}

function Hero({ backgroundImage, children }: Props): Node {
  return <Base backgroundImage={backgroundImage}>{children}</Base>
}
Hero.defaultProps = {
  children: null,
}

export default Hero

const Base: ComponentType<{}> = styled.div`
  background-image: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.2)
    ),
    url(${({ backgroundImage }) => backgroundImage});
  background-position: center;
  background-size: cover;
  height: 20rem;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.4);

  @media screen and (min-width: ${theme.screens.sm}) {
    height: 35rem;
    background-position: 50% 40%;
  }
`
