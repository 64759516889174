// @flow

import React from 'react'
import type { Node, Element } from 'react'
import cx from 'classnames'

type Props = {
  htmlFor: string,
  children: Node,
  id?: string,
  className?: string,
}

function FormLabel({
  children,
  className,
  id,
  htmlFor,
}: Props): Element<'label'> {
  return (
    <label className={cx('w-full block', className)} id={id} htmlFor={htmlFor}>
      {children}
    </label>
  )
}
FormLabel.defaultProps = {
  id: undefined,
  className: undefined,
}

export default FormLabel
