// @flow

import type { Element } from 'react'
import React from 'react'
import cx from 'classnames'

type Props = {
  src: string,
  alt?: string,
  className?: string,
}

function Image({ src, alt, className }: Props): Element<'img'> {
  return (
    <img
      className={cx('w-full rounded-sm block', className)}
      src={src}
      alt={alt}
    />
  )
}
Image.defaultProps = {
  alt: '',
  className: '',
}

export default Image
