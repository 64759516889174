// @flow

import { css } from 'styled-components'
import type { CSSRules } from 'styled-components'

import { rgba } from 'polished'
import theme from '../../tailwind'

type InputState = {
  default: string,
  error: string,
  focus: string,
}

type StyledInputProps = { error: string }

const defaultColor = theme.colors.transparent
const errorColor = theme.colors['red-light']

const borderColor: InputState = {
  default: defaultColor,
  focus: theme.colors['indigo-dark'],
  error: errorColor,
}

const focusColor: InputState = {
  default: defaultColor,
  focus: rgba(theme.colors['indigo-lighter'], 0.5),
  error: rgba(errorColor, 0.2),
}

const getColor =
  (inputState: InputState, focus?: boolean = false) =>
  ({ error }: StyledInputProps) => {
    if (error) return inputState.error
    if (focus) return inputState.focus
    return inputState.default
  }

export const inputStyles: CSSRules = css`
  box-shadow: 0 0 0 3px ${focusColor.default};
  border-color: ${getColor(borderColor)};
  transition-property: box-shadow, border-color;
  transition-timing-function: ease-in-out;
  transition-duration: 250ms;

  &:focus {
    outline: none;
    border-color: ${getColor(borderColor, true)};
    box-shadow: 0 0 0 3px ${getColor(focusColor, true)};
  }
`

export default inputStyles
