// @flow

import * as React from 'react'

type Props = {
  children: React.Node,
  className: string,
}
function StyledList({ children, className }: Props): React.Element<'ul'> {
  return (
    <ul
      className={className}
      style={{ listStyle: 'inside', paddingLeft: '1rem' }}
    >
      {children}
    </ul>
  )
}

export default StyledList
