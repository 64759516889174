// @flow

import type { Node, ComponentType } from 'react'
import React from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import { rgba } from 'polished'
import theme from '../../../tailwind'

type Props = {
  id: string,
  className?: string,
  dangerouslySetInnerHTML: {|
    __html: string,
  |},
}

function MarkdownContent({
  id,
  className,
  dangerouslySetInnerHTML,
}: Props): Node {
  return (
    <Content id={id} className={cx('md-content mb-10 md:mb-24', className)}>
      <div dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
    </Content>
  )
}
MarkdownContent.defaultProps = {
  className: '',
}

const Content: ComponentType<{}> = styled.div`
  h1 {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  ul {
    list-style: initial;
    padding-left: 1rem;
    margin-bottom: 1rem;
  }

  ul li {
    margin-left: 1rem;
    line-height: 1.3;
  }

  p {
    font-size: 1.125rem;
    margin-bottom: 1.5rem;
    line-height: 1.4;
    color: ${theme.colors['grey-darkest']};
  }

  @media screen and (min-width: 0) and (max-width: ${theme.screens.md}) {
    p > img {
      width: calc(100% + 3rem);
      max-width: calc(100% + 3rem);
      margin-right: -1.5rem;
      margin-left: -1.5rem;
    }
  }

  .side-by-side {
    display: flex;
    margin-bottom: 1rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .side-by-side__image {
    width: 50%;
    padding-left: 0.25rem;
    padding-right: 0.25rem;

    @media screen and (min-width: ${theme.screens.md}) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    & + & {
      margin-top: 0.5rem;
    }
  }

  blockquote {
    border-left: 3px solid grey;
    padding-left: 1rem;
  }

  strong {
    font-weight: 600;
  }

  em {
    font-style: italic;
  }

  a[href] {
    color: ${theme.colors['purple-light']};
    border-bottom: 1px solid ${rgba(theme.colors['purple-light'], 0)};
    transition: all 250ms ease-in-out;
    text-decoration: none;

    &:hover {
      color: ${theme.colors['purple-light']};
      border-bottom-color: ${rgba(theme.colors['purple-light'], 0.5)};
    }

    &:active {
      color: ${theme.colors['purple-lighter']};
      border-bottom-color: ${rgba(theme.colors['purple-lighter'], 0.5)};
    }
  }

  h1,
  h2,
  h3 {
    margin-bottom: 1rem;
    font-weight: ${theme.fontWeights.bold};
    letter-spacing: ${theme.leading.tight};
  }

  h1 {
    font-size: ${theme.textSizes['4xl']};
  }

  h2 {
    font-size: ${theme.textSizes['3xl']};
  }

  h3 {
    font-size: ${theme.textSizes['2xl']};
  }

  hr {
    border-bottom: 2px solid ${theme.colors['grey-light']};
    margin-bottom: 2rem;
  }
`

export default MarkdownContent
