// @flow

import React from 'react'
import type { Node } from 'react'

import styled from 'styled-components'
import { useField } from 'formik'
import type { FormikContext } from 'formik'
import { inputStyles } from '../../global-styles'

import FormLabel from '../form-label'
import { FieldError } from '../field-error'
import type { ContactPageForm } from '../../types'

type FieldName =
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'phone'
  | 'message'
  | 'botField'

type TextareaFieldProps<T> = {
  name: FieldName,
  content: ContactPageForm,
  form: FormikContext<T>,
}

export function TextareaField<Values: any>({
  name,
  content,
  form,
}: TextareaFieldProps<Values>): Node {
  const [field] = useField<Values>(name)
  const { value, onBlur, onChange } = field
  const { touched, errors } = form
  return (
    <FormLabel htmlFor={name}>
      <div className="mb-2">{content.form.message.label}</div>
      <FieldError formik={{ field, form }}>
        <StyledTextarea
          className="w-full block rounded p-4 border border-solid resize-none"
          row="10"
          name={name}
          placeholder={content.form[name].placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          error={touched[field.name] && errors[field.name]}
        />
      </FieldError>
    </FormLabel>
  )
}

export default TextareaField

const StyledTextarea = styled.textarea`
  ${inputStyles}
`
