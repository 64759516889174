// @flow

import React from 'react'
import type { Node } from 'react'
import cx from 'classnames'

import styled from 'styled-components'
import { rgba } from 'polished'
import type { FieldProps } from 'formik'

import theme from '../../../tailwind'

type FieldErrorProps<Values> = {
  children: Node,
  formik: {
    form: FieldProps<Values>['form'],
    field: FieldProps<Values>['field'],
  },
  className?: string,
}

export function FieldError<Values: any>({
  children,
  formik: { form, field },
  className,
}: FieldErrorProps<Values>): Node {
  const { touched, errors } = form
  const hasError = touched[field.name] && errors[field.name]
  return (
    <Container className={cx(className, 'rounded-lg', hasError && 'p-1')}>
      <div className={cx(hasError && 'mb-1')}>{children}</div>
      {hasError ? (
        <ErrorContainer className="flex items-center rounded overflow-hidden">
          <div className="text-red-light bg-white p-3 mr-2 w-5 h-5 flex items-center justify-center">
            i
          </div>
          <ErrorText className="text-black leading-none text-grey-darkest text-sm font-medium relative">
            {errors[field.name]}
          </ErrorText>
        </ErrorContainer>
      ) : null}
    </Container>
  )
}
FieldError.defaultProps = {
  className: '',
}

export default FieldError

const Container = styled.div`
  background-color: ${rgba(theme.colors.white, 0.5)};
  backdrop-filter: blur(10);
`

const ErrorContainer = styled.div`
  background-color: ${rgba(theme.colors.white, 0.5)};
`

const ErrorText = styled.p`
  top: -1px;
`
