// @flow

import React from 'react'
import type { Node } from 'react'

import cx from 'classnames'
import styled from 'styled-components'
import type { FormikHandlers } from 'formik'
import { inputStyles } from '../../global-styles'

type Props = {
  name: string,
  onChange: FormikHandlers['handleChange'],
  value: any,
  placeholder: string,
  className?: string,
  onBlur?: FormikHandlers['handleBlur'],
  error?: boolean,
  type?: string,
  required?: boolean,
  readOnly?: boolean,
}

export function TextInput({
  name,
  placeholder,
  onChange,
  onBlur,
  error,
  value,
  className,
  type,
  required,
  readOnly,
}: Props): Node {
  return (
    <StyledInput
      className={cx('w-full block rounded p-4 border border-solid', className)}
      placeholder={placeholder}
      name={name}
      type={type}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      error={error}
      required={required}
      readOnly={readOnly}
    />
  )
}
TextInput.defaultProps = {
  type: 'text',
  className: '',
  required: false,
  readOnly: false,
  error: false,
  onBlur: undefined,
}

export default TextInput

const StyledInput = styled.input`
  ${inputStyles}
`
